import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Container, TextField, Button, Typography, Box, CircularProgress, MenuItem, CssBaseline, Grid } from '@mui/material';
import { useTheme } from '../context/ThemeContext';
import CryptoJS from 'crypto-js';
import Modal from '../components/Modal'; // Import Modal component

// Validation schema
const schema = yup.object().shape({
  childUsername: yup.string().required('Child Username is required'),
  childPassword: yup.string().min(4, 'Child password must be at least 4 characters long').required('Child Password is required'),
  childName: yup.string().required('Child Name is required'),
  childAge: yup.number().min(1).max(18).required('Child Age is required')
});

// Encryption function
function encryptData(data) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
}

// Decryption function
function decryptData(data) {
  const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

const ParentDashboard = () => {
  const { isDarkTheme } = useTheme();
  const [childrenDetails, setChildrenDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    const fetchChildrenDetails = async () => {
      try {
        const response = await fetch('https://www.funaitutor.co.uk/child/child-details', {
          method: 'GET',
          credentials: 'include'
        });
        const data = await response.json();
        const decryptedData = decryptData(data.reply);  // Decrypting the data
        setChildrenDetails(decryptedData);
      } catch (error) {
        console.error('Error fetching children details:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchChildrenDetails();
  }, []);

  const onSubmit = async (data) => {
    setErrorMessage('');  // Reset the error message
    try {
      // Normalise the child username and any other relevant fields to lowercase
      const normalisedData = {
        ...data,
        childUsername: data.childUsername.trim().toLowerCase()
      };
  
      const encryptedData = encryptData(normalisedData);  // Encrypt data before sending
  
      const response = await fetch('https://www.funaitutor.co.uk/child/addchild', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ encryptedData })  // Send encrypted data
      });
  
      if (response.status === 400) {
        const errorData = await response.json();
        setModalTitle("Error");
        setModalMessage(errorData.message);
        setModalShow(true);
        const id = setTimeout(() => {
          setModalShow(false);
        }, 2000);
        setTimeoutId(id);
        return;
      }
  
      const newChild = await response.json();
      const decryptedChild = decryptData(newChild.reply);  // Decrypt the new child data
      setChildrenDetails([...childrenDetails, { ...decryptedChild, conversationCount: 0, messageCount: 0 }]);
      reset();
      setModalTitle("Success");
      setModalMessage("Child added successfully!");
      setModalShow(true);
      const id = setTimeout(() => {
        setModalShow(false);
      }, 2000);
      setTimeoutId(id);
    } catch (error) {
      console.error('Failed to add child:', error);
      setModalTitle("Error");
      setModalMessage("Failed to add child. Please try again!");
      setModalShow(true);
      const id = setTimeout(() => {
        setModalShow(false);
      }, 2000);
      setTimeoutId(id);
    }
  };  

  const handleCloseModal = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setModalShow(false);
  };

  const ages = Array.from({ length: 18 }, (_, i) => i + 1);

  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ bgcolor: isDarkTheme ? '#212121' : '#f0f0f0', minHeight: '100vh', pt: { xs: 10, sm: 12 }, pb: { xs: 3, sm: 5 } }}>
        <Container component="main" maxWidth="md">
          <Typography variant="h4" sx={{ color: isDarkTheme ? 'white' : 'inherit', fontWeight: 'bold', mb: 4 }}>
            Parent Dashboard
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: isDarkTheme ? 'white' : 'inherit' }}>Your Children</Typography>
              {loading ? (
                <CircularProgress />
              ) : (
                <Box sx={{ mt: 2, p: 2, boxShadow: 3, borderRadius: 1, backgroundColor: isDarkTheme ? '#333' : '#fff' }}>
                  <ul>
                    {childrenDetails.map(child => (
                      <li key={child._id} style={{ color: isDarkTheme ? 'white' : 'black' }}>
                        {child.name} (Age: {child.age}) - Conversations: {child.conversationCount}, Messages: {child.messageCount}
                      </li>
                    ))}
                  </ul>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ p: 2, boxShadow: 3, borderRadius: 1, backgroundColor: isDarkTheme ? '#333' : '#fff' }}>
                <Typography variant="h6" sx={{ color: isDarkTheme ? 'white' : 'inherit' }}>Add New Child</Typography>
                {errorMessage && (
                  <Typography variant="body1" color="error" sx={{ mt: 1 }}>{errorMessage}</Typography>
                )}
                <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, width: '100%' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        {...register("childName")}
                        label="Child Name"
                        error={!!errors.childName}
                        helperText={errors.childName?.message}
                        sx={textFieldStyle(isDarkTheme)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        {...register("childAge")}
                        label="Child Age"
                        select
                        error={!!errors.childAge}
                        helperText={errors.childAge?.message}
                        sx={textFieldStyle(isDarkTheme)}
                      >
                        {ages.map(age => (
                          <MenuItem key={age} value={age}>
                            {age}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        {...register("childUsername")}
                        label="Child Username"
                        error={!!errors.childUsername}
                        helperText={errors.childUsername?.message}
                        sx={textFieldStyle(isDarkTheme)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        {...register("childPassword")}
                        label="Child Password"
                        type="password"
                        error={!!errors.childPassword}
                        helperText={errors.childPassword?.message}
                        sx={textFieldStyle(isDarkTheme)}
                      />
                    </Grid>
                  </Grid>
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: 'primary.main' }}>
                    Add Child
                  </Button>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Modal show={modalShow} handleClose={handleCloseModal} title={modalTitle} message={modalMessage} />
    </React.Fragment>
  );
};

// Style for TextFields
function textFieldStyle(isDarkTheme) {
  return {
    '& .MuiInputBase-input': { color: isDarkTheme ? 'white' : 'black' },
    '& label.Mui-focused': { color: isDarkTheme ? 'white' : 'black' },
    '& .MuiOutlinedInput-root': {
      '& fieldset': { borderColor: isDarkTheme ? 'white' : 'black' },
      '&:hover fieldset': { borderColor: isDarkTheme ? 'white' : 'black' },
      '&.Mui-focused fieldset': { borderColor: isDarkTheme ? 'white' : 'black' },
    },
  };
}

export default ParentDashboard;
