import React, { useState, useEffect } from 'react';
import Chat from './Chat';
import ChatHistory from './ChatHistory';
import { Typography, CssBaseline, Box, IconButton, useMediaQuery, Grid } from '@mui/material';
import { useTheme } from '../context/ThemeContext';
import { ChatProvider } from '../context/ChatContext';
import CryptoJS from 'crypto-js';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

// Function to decrypt data (unchanged)
function decryptData(data) {
  try {
    if (!data) return null;
    const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error('Decryption failed:', error);
    return null;
  }
}

const ChatbotPage = () => {
  const { isDarkTheme, theme } = useTheme();
  const [storedConversations, setStoredConversations] = useState([]);
  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const [isChatHistoryOpen, setIsChatHistoryOpen] = useState(!isSmallScreen);

  // Define component styling based on theme
  const backgroundColor = isDarkTheme ? '#212121' : '#f0f0f0';
  const iconColor = isDarkTheme ? '#ffffff' : '#000000';  // Set icon color based on theme
  const chatHistoryBgColor = isDarkTheme ? '#333' : '#fff';
  const boxShadow = '2px 0 5px rgba(0, 0, 0, 0.1)';
  const navbarHeight = '64px'; // Height of the Navbar

  // Toggle chat history panel
  const toggleChatHistory = () => {
    setIsChatHistoryOpen(!isChatHistoryOpen);
  };

  useEffect(() => {
    setIsChatHistoryOpen(!isSmallScreen);
  }, [isSmallScreen]);

  // Load stored conversations from localStorage after session verification
  useEffect(() => {
    const encryptedConversations = localStorage.getItem('conversations');
    const conversations = encryptedConversations ? decryptData(encryptedConversations) : [];
    setStoredConversations(conversations || []);
  }, []);

  // Only render ChatProvider if the user is authenticated
  return (
    <ChatProvider storedConversations={storedConversations}>
      <CssBaseline />
      <Grid container sx={{ bgcolor: backgroundColor, minHeight: '100vh', display: 'flex' }}>
        
        {/* Chat History Section */}
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            width: { xs: '250px', sm: '250px' },
            height: { xs: `calc(100vh - ${navbarHeight})`, sm: `calc(100vh - ${navbarHeight})` },
            position: { xs: 'fixed', sm: 'fixed' },
            top: navbarHeight,
            left: { xs: isChatHistoryOpen ? 0 : '-250px', sm: 0 },
            overflowY: 'auto',
            backgroundColor: chatHistoryBgColor,
            boxShadow: boxShadow,
            zIndex: 1000,
            transition: 'left 0.3s ease-in-out',
            flexShrink: 0,
          }}
        >
          <ChatHistory setIsChatHistoryOpen={setIsChatHistoryOpen} />
        </Grid>

        {/* Icon for toggling chat history on small screens */}
        {isSmallScreen && (
          <Box
            sx={{
              position: 'fixed',
              top: `calc(${navbarHeight} + 10px)`,
              left: isChatHistoryOpen ? '260px' : '10px',
              zIndex: 1100,
              transition: 'left 0.3s ease-in-out',
            }}
          >
            <IconButton
              onClick={toggleChatHistory}
              sx={{
                color: iconColor,
                backgroundColor: theme.palette.background.paper,
                borderRadius: '4px',
                padding: '4px 12px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: isDarkTheme ? '#666' : '#f0f0f0',
                },
              }}
            >
              {isChatHistoryOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </Box>
        )}

        {/* Chat and Main Content Section */}
        <Grid
          item
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: { xs: isChatHistoryOpen ? '250px' : 0, sm: '250px' },
            transition: 'margin-left 0.3s ease-in-out',
            minHeight: '100vh',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', px: 2, mb: 2 }}>
            <Typography
              variant={isSmallScreen ? 'h4' : 'h3'}
              gutterBottom
              component="h1"
              sx={{ color: iconColor, textAlign: 'center', flexGrow: 1 }}
            >
              FunAI Chat
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
            <Chat />
          </Box>
        </Grid>
      </Grid>
    </ChatProvider>
  );
};

export default ChatbotPage;
