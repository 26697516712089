import React from 'react';
import { Container, Typography, Card, CardContent, Grid, Box, CssBaseline } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import ChatIcon from '@mui/icons-material/Chat';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import { useTheme } from '../context/ThemeContext';

const HomePage = () => {
  const muiTheme = useMuiTheme();
  const { isDarkTheme } = useTheme();

  const cardBackgroundColor = isDarkTheme ? '#212121' : '#f0f0f0';
  const textColor = isDarkTheme ? '#ffffff' : 'inherit';
  const borderColor = isDarkTheme ? '#757575' : '#e0e0e0';
  const iconColor = isDarkTheme ? 'white' : '#123456';
  const backgroundColor = isDarkTheme ? '#212121' : '#f0f0f0';

  const cardStyle = {
    padding: muiTheme.spacing(2),
    backgroundColor: cardBackgroundColor,
    boxShadow: 3,
    borderColor,
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Box 
        sx={{ 
          backgroundColor, 
          minHeight: '100vh', 
          pt: { xs: 8, sm: 10 }, // Added padding to prevent overlap
          pb: { xs: 3, sm: 5 } 
        }}>
        <Container maxWidth="md">
          <Typography variant="h2" gutterBottom sx={{ fontWeight: 'bold', color: textColor, fontSize: { xs: '2rem', sm: '3rem', md: '4rem' } }}>
            <SchoolIcon fontSize="inherit" sx={{ color: iconColor, mr: 1 }} /> Welcome to FunAITutor!
          </Typography>
          <Card variant="outlined" sx={{ ...cardStyle }}>
            <CardContent>
              <Typography variant="h5" gutterBottom sx={{ color: textColor, fontSize: { xs: '1rem', sm: '1.5rem' } }}>
                FunAITutor is a child-friendly platform that helps kids learn and practice language skills in a fun and interactive way.
              </Typography>
            </CardContent>
          </Card>
          <Grid container spacing={3} justifyContent="center" mt={3}>
            <Grid item xs={12} sm={6}>
              <Card variant="outlined" sx={{ ...cardStyle }}>
                <CardContent>
                  <Typography variant="body1" gutterBottom sx={{ color: textColor, display: 'flex', alignItems: 'center', fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                    <ChatIcon sx={{ color: iconColor, mr: 1 }} /> Our intelligent AI chatbot adapts to your child's learning style and pace, providing a personalized learning experience.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card variant="outlined" sx={{ ...cardStyle }}>
                <CardContent>
                  <Typography variant="body1" gutterBottom sx={{ color: textColor, display: 'flex', alignItems: 'center', fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                    <AccessTimeIcon sx={{ color: iconColor, mr: 1 }} /> With FunAITutor, your child can practice at any time and from anywhere, making learning convenient and flexible.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default HomePage;
